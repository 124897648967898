@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For small screens, reduce the width of the container */
@media (max-width: 640px) {
  .custom-container {
    max-width: calc(
      100vw - 2rem
    ); /* Adjust the 2rem value to control the container width */
    margin-left: 1rem; /* Adjust the 1rem value to control the left margin */
    margin-right: 1rem; /* Adjust the 1rem value to control the right margin */
  }
}
